import { Link } from 'react-scroll';
// import ParticlesBg from "particles-bg";
import Typed from 'react-typed';


function Header() {
//   let config = {
//     num: [1, 2],
//     rps: 0.1,
//     radius: [0, 4],
//     life: [1.5, 3],
//     v: [2, 3],
//     tha: [-40, 40],
//     // body: "./img/icon.png", // Whether to render pictures
//     rotate: [0, 20],
//     alpha: [0.6, 0],
//     scale: [1, 0.1],
//     position: "all", // all or center or {x:1,y:1,width:100,height:100}
//     color: ["random", "#ff0000"],
//     cross: "dead", // cross or bround
//     random: 15,  // or null,
//     g: 5,    // gravity
//     f: [2, -1], // force
//     onParticleUpdate: (ctx, particle) => {
//         ctx.beginPath();
//         ctx.rect(particle.p.x, particle.p.y, particle.radius * 2, particle.radius * 2);
//         ctx.fillStyle = particle.color;
//         ctx.fill();
//         ctx.closePath();
//   }
// };

  

  return (
    <header>
      <video src="/design.mp4" loop autoPlay muted></video>
      <h1 className='animated fadeInLeftBig ' style={{fontFamily: "monospace" }}> 
      <Typed
            strings={[
              '&lsaquo; NOG Dev House &#47; &rsaquo;',
              'Developement Web',
              'Developement Mobile',
              'Services et Conseils',
              
          ]}
            typeSpeed={200}
        loop/>
      </h1>
      
      <div className="row">
        <Link 
          className="btn animated fadeInRightBig"
          spy={true}
          smooth={true}
          duration={1000}
          to="services"
          style={{ cursor: "pointer" }}
          >Lire Plus
        </Link>
        {/* <Link 
        className="btn animated fadeInRightBig"
        spy={true}
        smooth={true}
        duration={1000}
        to="contact"
        style={{ cursor: "pointer" }}
        >Facturation
      </Link> */}
      </div>
      {/* <div className="headerbg">

      </div> */}
      {/* <ParticlesBg type="lines" config={config} bg={true} /> */}

      <div className="headerbg"></div>
      
    </header>
  );
}
export default Header;
