import React from 'react'
import "../styles/services.css"

export const ServiceItem = ({service}) =>{
  return (
    <>
    
        <div className=" timeline__event animated fadeInUp delay-1s timeline__event--type3 ">
          <div className="timeline__event__icon">
          </div>
          <div className="timeline__event__date">
            Service
          </div>
          <div className="card timeline__event__content">
            <div className="timeline__event__title">
              {service.title}
            </div>
            <div className="timeline__event__description">
              <p className='code'>{service.text}</p>
            </div>
          </div>
        </div>
    </>
  )
}
