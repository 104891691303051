import { useState } from "react";
import { ServiceItem } from './ServiceItem';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Typed from 'react-typed';



// import "../styles/services.css"
function Services() {
    const [services] = useState(
        [
            {
                title:"Maintenances informatique",
                text:"Grace à son équipe dynamique fiable et travailleurs, le NOG Dev House Dans le but de permettre aux entreprises de rester permanemment à leur plein potentiel de Productivité, nous proposons des types de contrat de maintenance :"
            },
            {
                title:"Conception, Administration des réseaux locaux et audit de leur sécurité",
                text:"Vous avez besoin de relier vos ordinateurs ou vos équipement informatiques (camera, imprimante, ordinateur…) en vue de profiter des meilleures performances de vos Equipment et partager les ressources ? Plus de soucis, présentez-nous vos besoins et nous les réaliserons pour vous."
            },
            {
                title:"Gestion des packs informatique",
                text:"La force d’une entreprise étant sa capacité à disposer, utiliser et gérer ses ressources, le NOG Dev House vous propose des solutions de gestion de vos ordinateurs et équipements informatique. Vous pouvez ainsi nous faire confiance dans l’entretien de vos équipements (maintenance) et dans l’achat et le renouvellement de vos équipements. (Fourniture de Matériel) ainsi que l’inventaire de vos ressources."
            },
            {
                title:"Vente de matériel neufs ou d'occasion",
                text:"Avec son grand réseau de fournisseurs (nationaux et internationaux) le NOG Dev House vous propose des équipements (photocopieuses, routeurs, ordinateurs…) adapté à votre projet et à vos entreprises. Vous avez des projets confié les nous et nous nous proposeront un devis adapté à votre projet. Nous vendons aussi des matériels d’occasion. Vous avez des équipements dont vous voulez vous débarrasser tout en gagnant une part du prix d’achat ? faites un tour sur le site du NOG Dev house pour déclarer votre vente."
            },
            {
                title:"Conception de logiciels",
                text:`Avec son équipé de Développeur d’application, le NOG Dev House vous propose des solutions pour numériser et automatiser les traitements de votre entreprises. Nous concevons et développons Des logiciels de gestion de boutique, institutions scolaire, d’internat, de micro finance, d’ employés, hôpital, pharmacie….
                    Vous nous présentée votre entrepris vos idées et nous on vous traduit en logiciel adapté à vos désirs.
                    Nous concevrons aussi des applications métier qui sont adapté uniquement à votre entreprise.
                    Pour vos entreprises déjà existantes, le NOG Dev House propose des solutions de la conception de vos site web pour agrandir la visibilité de vos entreprises et des contrats de gestion de contenu pour vos site déjà existant.
                    Nous vous offrons des solutions de référencement pour agrandir votre visibilité sur le NET.`
            },
            {
                title:"Pack email professionnel et marketing viral) SMS",
                text:"Pour permettre aux agents de l’entreprise et ne pas mélanger vie professionnelle et privée, le NOG Dev House vous propose des solutions d’email Professionnel et de marketing par SMS pour informer vos client et avoir une grande plage de publicité."
            },
            {
                title:"Montages audio et conception graphique",
                text:"Pour votre visibilité, nous vous proposons des publicités, audio et des affiches publicitaires pour annoncer vos évènements"
            },

        ]
    )
    return (
        <div className="container services">
            <AnimationOnScroll animateIn="fadeInUp">

                <h2 className="main-title text-center animated fadeInUp">SERVICES</h2>
                <p className="code timeline__event__content center">

                <Typed
                    strings={[`>>> Services <br/><br/>
                    Avec nous, Concrétisez vos idées!

                    Bien plus qu’un métier,
                    nous sommes une team passionnée du digital. 
                    Nous partageons le meilleur de notre savoir-faire 
                    pour vous accompagner dans la concrétisation de vos projets informatiques.

                    Bref Une expérience client unique dans les délais & budget.
                    <br/>
                    
                    >>> `]}
                    typeSpeed={20}
                />
                    
                </p>
                <div className="card-cover">
                    <div className="timeline"> 
                        {
                            services.map((item,index)=>(
                                <ServiceItem key={index} service={item}/>
                            ))
                        }
                    </div>
                </div>
            </AnimationOnScroll>

        </div>
    );
}
export default Services;
