import React from "react";
import './ebbaPolicy.css';
const EbbaPolicy = () =>  {
  return (
    <div className="container-policy">
      <h1>Privacy Policy for Cantique EBB-TOGO</h1>
      <p><strong>Last Updated:</strong> April 11, 2024</p>
      <p>Welcome to Cantique EBB-TOGO! Your privacy is a priority for us. This Privacy Policy outlines the measures we take to protect your data and ensure transparency.</p>

      <h2>1. Introduction</h2>
      <p>This Privacy Policy applies to the Cantique EBB-TOGO app provided by NOG-DevHouse. It explains how we handle and protect your personal and sensitive data when you use our app.</p>

      <h2>2. No Collection of Personal and Sensitive Data</h2>
      <p>Cantique EBB-TOGO does not access, collect, or store any personal or sensitive user data. We do not require any permissions that would grant access to such data.</p>

      <h2>3. Data Security</h2>
      <p>Even though we do not collect personal or sensitive data, we have implemented industry-standard security measures to safeguard any information you may provide while using our app.</p>

      <h2>4. Data Handling Procedures</h2>
      <ul>
        <li>Data Access: Our app does not access any personal or sensitive user data.</li>
        <li>Data Collection: We do not collect any personal or sensitive user data.</li>
        <li>Data Use: We do not use any personal or sensitive user data.</li>
        <li>Data Sharing: We do not share any personal or sensitive user data with third parties.</li>
      </ul>

      <h2>5. Data Retention and Deletion Policy</h2>
      <p>Since we do not collect any personal or sensitive user data, we do not retain or store any data on our servers or within the app.</p>

      <h2>6. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>
      <ul>
        <li>Email: <a href="mailto:support@nogdevhouse.com">support@nogdevhouse.com</a></li>
        <li>Whatsapp: +22897307159</li>
        <li>Phone: +22890222245</li>
      </ul>

      <h2>7. Disclaimer</h2>
      <p>This Privacy Policy is provided for informational purposes only and does not constitute legal advice. If you have legal concerns about privacy, please consult with a legal professional.</p>

      <h2>8. Privacy Policy Accessibility</h2>
      <p>This Privacy Policy is available on an active, publicly accessible, and non-geofenced URL at <a href="https://nogdevhouse.com/ebba-privacy-policy">https://nogdevhouse.com/ebba-privacy-policy</a>. It is non-editable and can be read in a standard browser without any plug-ins or special handlers.</p>

      <h2>9. Linking in Play Console</h2>
      <p>The Privacy Policy is linked on the Cantique EBB-TOGO app’s store listing page in Play Console and is accessible within the app itself.</p>

      <p>Thank you for using Cantique EBB-TOGO! We are committed to protecting your privacy and ensuring a safe and enjoyable experience while using our app.</p>
    </div>
  );
}
export default EbbaPolicy;