import { Link, animateScroll as scroll } from 'react-scroll'

function Footer() {


  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-2">
                <h1 className="logo">NOG Dev House</h1>
                <p className="footer-text">
                  Votre Satisfaction est notre priorité
                </p>
                <p className="footer-text">
                    © Copyright 2021 Reserved to NOG-DevHouse
                </p>

              </div>
              <div className="col-md-3">
                <p className="footer-title">Contact Info</p>
                <ul>
                  <li>
                    Mango TOGO, 06 Lysama Street
                  </li>
                  <li>
                    West Africa Lomé -TOGO
                  </li>
                  <li>
                    Phone : +228 97-30-71-59 / +228 90-22-22-45
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;
