import { AnimationOnScroll } from 'react-animation-on-scroll';
import Typed from 'react-typed';

export const Products=() =>{
  return (
    <>
      <AnimationOnScroll animateIn="animated fadeInUp">
        <div className="container products" style={{marginBottom:'10rem'}}>
        <h2 className="main-title text-center">Nos Produicts</h2>
        <div style={{marginTop:'5rem',width:'100%',height:'100px'}} className="products "></div>

          <div className="row">
            <div className="col-md-6 text-center">
              <img alt="products " src="../img/produits.png" className="img-fluid animated flipInX" />
            </div>
            <div className="   col-md-6 animated bounceIn timeline__event__content">
              <h2 className="main-title about-h2">Les Produits NOG Dev House</h2>
              <p className="code ">
                
                <Typed
                    strings={[`>>> Produits <br/> <br/> Nous avons differents  produits NOG DEV HOUSE conçus conformement aux demandes
                    et aux besoins des clients pour votre usage.
                    Il vous suffirairait donc de nous adresser votre demande pour en posséder.
                    Nous avons par exemple un gestionnaire d'ecole, 
                    un gestionnaire de stock et plusieurs autres encore. <br/>
                    
                    >>> `]}
                    typeSpeed={40}
                />
              </p>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      
    </>
  );
}