import { AnimationOnScroll } from "react-animation-on-scroll";
function Contact() {
  return (
    <AnimationOnScroll animateIn="fadeIn">
      <div className="container contact ">
        <h2 className="main-title text-center">CONTACT</h2>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-1">
              <input name="name" placeholder="Nom" className="contact-input" />
            </div>

            <div className="col-md-4 mb-1">
              <input name="email" placeholder="Email" className="contact-input" />
            </div>
            <div className="col-md-4 mb-1">
              <input
                name="subject"
                placeholder="Message"
                className="contact-input"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12">
        <textarea
              name="message"
              placeholder="Message"
              className="contact-textarea"
            />
        </div>

        <br></br>
        <div className="row">
          <div className="col-md-12">
            <input className="form-btn" type="submit" value="Envoyer" />
          </div>
        </div>
      </div>
    </AnimationOnScroll>
  );
}
export default Contact;
