import Home from "./pages/Home";
import EbbaPolicy from "./pages/EbbaPolicy";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route  path="/" element={<Home/>}/>
        <Route  path="/ebba-privacy-policy" element={<EbbaPolicy/>}/>
      </Routes>
    </BrowserRouter> );
}

export default App;
