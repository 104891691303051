import Footer from "../components/Footer";
import Header from "../components/Header";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import "../styles/animate.css"
import "animate.css/animate.min.css";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Header/>
      <Main />
      {/* <Footer /> */}
    </div>
  );
}

export default Home;