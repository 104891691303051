import { AnimationOnScroll } from 'react-animation-on-scroll';
function About() {
  return (
    <>
      <AnimationOnScroll animateIn="fadeInUp">

        
        <div className="container about" style={{marginBottom:'10rem'}}>
            <h2 className="main-title text-center">À Propos</h2>
            <div style={{marginTop:'5rem',width:'100%',height:'100px'}} ></div>

          <div className="row">
            <div className="col-md-6 text-center">
              <img alt="about" src="../img/img1.png" className="img-fluid" />
            </div>
            <div className="col-md-6 timeline__event__content">
              <h2 className="main-title about-h2">À Propos de nous</h2>
              <b>
                <p className='code'>
                
                Nog Dev House est une entreprise Togolaise de développement informatique. 
                Nos services incluent : le développement de logiciels sur 
                mesure (selon la demande), développement de site web,
                développement d'applications mobiles , maintenance reseau 
                et plusieurs autres domaines informatiques.<br/><br/>

                Nous avons complété plusieurs projets à travers le Togo et même à l'international.
                Nous nous engageons à fournir les meilleurs solutions qui répondent efficacement aux besoins de nos clients. 
                Nos offrons nos services aux particuliers, les petites entreprises, 
                les larges entreprises et organisations.<br/><br/>

                Que votre projet soit simple ou complexe, 
                on commence d'une simple stratégie embryonnaire 
                jusqu'á la finalisation du projet en considérant chaque étape du développement. <br/><br/>

                Nog Dev House a été fondé pour être une entreprise d'excellence. 
                Pour nous, c'est tout ou rien. Nous n'aimons pas le milieu. 
                Nous mettons du focus sur l'aspect créative et technique pour la réalisation des projets. 
                Notre objectif n'est pas seulement de créer mais d'innover. Faites notre connaissance dès maintenant.
              </p>
              <h3>
                
                POURQUOI NOUS CHOISIR ?
                
              
              </h3>
              <ul className='code'>
                <li>
                  NOUS FAISONS DES CLIENTS HEUREUX
                </li>
                <li>
                  NOUS UTILISONS LES DERNIÈRES TECHNOLOGIES PERFORMANTES
                </li>
                <li>
                  LA QUALITÉ EST NOTRE PRIORITÉ
                </li>
                <li>
                  VOTRE SUCCÈS NOUS IMPORTE!
                </li>
                <li>
                  NOUS VOULONS CRÉER UNE LONGUE RELATION AVEC VOUS
                </li>

              </ul>              </b>
            </div>
          </div>
      </div>
    </AnimationOnScroll>
    </>
  );
}
export default About;
